import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../../shared/config/routes-config';
import { UserRole } from '../../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../../shared/layouts/default/default.layout';

const FinancialProductsListContainer = React.lazy(
  () => import('../../containers/financial-products-list/financial-products-list.container')
);

const FinancialProductAddContainer = React.lazy(
  () => import('../../containers/financial-products-add/financial-products-add.component')
);

const FinancialProductEditContainer = React.lazy(
  () => import('../../containers/financial-products-edit/financial-products-edit.component')
);

export const FinancialProductsRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: (
      <Navigate
        to={(APP_ROUTES.FINANCIAL_PRODUCTS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH}
      />
    )
  },
  {
    path: (APP_ROUTES.FINANCIAL_PRODUCTS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_FINANCE,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.ADMIN
          ]}
          component={FinancialProductsListContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.FINANCIAL_PRODUCTS_MODULE.CHILDREN as RouteConfig).ADD_FINANCIAL_PRODUCT
      .FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_FINANCE,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.ADMIN
          ]}
          component={FinancialProductAddContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.FINANCIAL_PRODUCTS_MODULE.CHILDREN as RouteConfig).EDIT_FINANCIAL_PRODUCT
      .FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_FINANCE,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.ADMIN
          ]}
          component={FinancialProductEditContainer}
        />
      </React.Suspense>
    )
  }
];
