import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../shared/layouts/default/default.layout';
import FinanceApplicationReportFilterContainer from '../../containers/finance-application-report-filter/finance-application-report-filter.container';

const FinanceApplicationReportContainer = React.lazy(
  () =>
    import('../../containers/finance-application-report-list/finance-application-report.container')
);

const { FINANCE_APPLICATIONS_REPORT_FILTER, FINANCE_APPLICATIONS_REPORT } = APP_ROUTES
  .REPORTS_MODULE.CHILDREN as RouteConfig;

const financeApplicationsReportFilterPath = FINANCE_APPLICATIONS_REPORT_FILTER.FULL_PATH;
const financeApplicationsReportPath = FINANCE_APPLICATIONS_REPORT.FULL_PATH;

export const ReportsRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={financeApplicationsReportFilterPath} />
  },
  {
    path: financeApplicationsReportFilterPath,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN, UserRole.SALES_MANAGER, UserRole.FINANCE_OFFICER]}
          component={FinanceApplicationReportFilterContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: financeApplicationsReportPath,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN, UserRole.SALES_MANAGER, UserRole.FINANCE_OFFICER]}
          component={FinanceApplicationReportContainer}
        />
      </React.Suspense>
    )
  }
];
