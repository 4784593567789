export enum BtnTextStatus {
  SUBMIT_FOR_REVIEW = 'Submit for review',
  RESUBMIT = 'Resubmit',
  CANCEL = 'Cancel',
  APPROVE = 'Approve',
  REJECT = 'Reject',
  REQUIRES_UPDATES = 'Requires updates',
  PLACE_PURCHASE_ORDER = 'Place purchase order',
  PURCHASE_DONE = 'Purchase done',
  LICENCED = 'Licenced',
  AMOUNT_RECEIVED = 'Amount Received'
}

export enum FinanceApplicationStatus {
  DRAFT = 'draft',
  AWAITING_FEEDBACK = 'awaiting_feedback',
  RESUBMISSION_REQUIRED = 'resubmission_required',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  PURCHASE_IN_PROGRESS = 'purchase_in_progress',
  READY_FOR_LICENSE = 'ready_for_license',
  AWAITING_AMOUNT_RELEASE = 'awaiting_amount_release',
  BOOKING_CONFIRMED = 'booking_confirmed'
}

// need to be deleted! so please replace with the above enum whenever you get a chance
export enum FinAppStatus {
  DRAFT = 'Draft',
  AWAITING_FEEDBACK = 'Awaiting Feedback',
  RESUBMISSION_REQUIRED = 'Resubmission Required',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
  EXPIRED = 'Expired',
  PURCHASE_IN_PROGRESS = 'Purchase In Progress',
  READY_FOR_LICENSE = 'Ready For License',
  AWAITING_AMOUNT_RELEASE = 'Awaiting Amount Release',
  BOOKING_CONFIRMED = 'Booking Confirmed'
}

export enum FinAppCarAvailability {
  IN_STOCK = 'In Stock',
  PLACEHOLDER_CAR = 'Placeholder car'
}

export enum FinAppReportStatus {
  DRAFT = 'Draft',
  AWAITING_FEEDBACK = 'Awaiting Feedback',
  RESUBMISSION_REQUIRED = 'Resubmission Required',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
  EXPIRED = 'Expired',
  PURCHASE_IN_PROGRESS = 'Purchase In Progress',
  READY_FOR_LICENSE = 'Ready For License',
  AWAITING_AMOUNT_RELEASE = 'Awaiting Amount Release',
  BOOKING_CONFIRMED = 'Booking Confirmed'
}

export enum ApplicationReportStatusEnum {
  // DOES NOT MATCH ACTUAL STATUSES
  CREATED = 'created',
  UPDATED = 'updated',
  //

  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  BOOKING_CONFIRMED = 'booking_confirmed'
}

export enum RejectionReason {
  BAD_CREDIT_HISTORY = 'bad_credit_history',
  DBR = 'dbr',
  NEGATIVE_COMPANY = 'negative_company',
  NEGATIVE_AREA = 'negative_area'
}
