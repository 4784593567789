import { SalesUsersRoles } from '../../../Modules/SalesManagementModule/shared/enums/sales-roles.enum';
import { CreateSalesTeamMemberModel } from '../../models/teams.model';
import { CreateSalesTeamMemberUIModel } from '../../ui-models/teams.ui-model';

function mapToModel(teamMember: CreateSalesTeamMemberUIModel): CreateSalesTeamMemberModel {
  return {
    userId: teamMember.userId,
    role: teamMember.role.id as SalesUsersRoles,
    team: teamMember.team?.id ?? '',
    ...(teamMember.reportsTo?.id && { reportsTo: teamMember.reportsTo.id }),
    ...(teamMember.assignees?.length && { assignees: teamMember.assignees })
  };
}

const createSalesMemebersMapper = { mapToModel };

export default createSalesMemebersMapper;
