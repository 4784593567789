import { MenuIcons } from '../../config/menu-icons';
import { APP_ROUTES } from '../../config/routes-config';
import { MenuItems } from '../../enums/side-menu.enum';
import { SalesRoles, UserRole } from '../../enums/user-role.enum';
import { UserTeams } from '../../enums/user-teams.enum';
import { MenuConfigItem } from '../../interfaces/side-menu-item.interface';
import { sideMenuMapper } from '../../mappers/side-menu.mapper';
import { isUserSalesTeam } from '../helpers/auth-helper/auth-helper.service';

/*  Logic  
    - We extract from APP_ROUTES every route with IS_MENU = TRUE 
    - Create 'menuList' empty array
    - We check if the route has PARENT
      >> if false, we add the route in the 'menuList'
      >> if true, we check if there is a parent in the 'menuList' exists with the same name
        >> if false, we create a parent and add the route as a child for it
        >> if true, we add the route as a child for that parent
*/

export const getMenuList = (userRole?: UserRole[], userTeam?: UserTeams): MenuConfigItem[] => {
  const routeMenuList: MenuConfigItem[] = [];
  Object.keys(APP_ROUTES).forEach((routeItemKey: string) => {
    const isPermittedRole = APP_ROUTES[routeItemKey].PERMITTED_ROLES
      ? userRole &&
        (APP_ROUTES[routeItemKey].PERMITTED_ROLES as UserRole[]).filter((x) =>
          userRole?.includes(x)
        )?.length > 0
      : true;

    const isPermittedTeam = !isUserSalesTeam()
      ? isPermittedRole
      : APP_ROUTES[routeItemKey].PERMITTED_TEAMS
      ? userTeam &&
        (APP_ROUTES[routeItemKey].PERMITTED_TEAMS as UserTeams[]).filter((x) =>
          userTeam?.includes(x)
        )?.length > 0
      : true;

    if (APP_ROUTES[routeItemKey].IS_MENU && isPermittedRole && isPermittedTeam) {
      routeMenuList.push(sideMenuMapper(routeItemKey));
    }
  });

  const menuList: MenuConfigItem[] = [];
  routeMenuList.map((item) => {
    if (item.PARENT) {
      if (menuList.some((menuItem) => menuItem.DISPLAY_NAME === item.PARENT)) {
        menuList.map((nestedItem) => {
          if (nestedItem.DISPLAY_NAME === item.PARENT) {
            nestedItem.CHILDREN?.push(item);
          }
        });
      } else {
        menuList.push({
          FULL_PATH: '',
          NAME: item.PARENT?.toLowerCase(),
          DISPLAY_NAME: item.PARENT,
          ICON: MenuIcons[MenuItems[item.PARENT as MenuItems]],
          CHILDREN: [item]
        });
      }
    } else {
      menuList.push(item);
    }
  });

  return menuList;
};
