/**
 * UserRole enum to define survey types
 */
export enum UserRole {
  SUPER_ADMIN = 'superAdmin',
  SALES_AGENT = 'salesAgent',
  SALES_MANAGER = 'salesManager',
  MARKETING_EXECUTIVE = 'marketingExecutive',
  FINANCE_OFFICER = 'financeOfficer',
  E_COMMERCE_AUTO = 'eCommerceAuto',
  E_COMMERCE_FINANCE = 'eCommerceFinance',
  E_COMMERCE_MANAGER = 'eCommerceManager',
  OPERATION_MANAGER = 'operationManager',
  FINANCE_MERCHANT_AGENT = 'financeMerchantAgent',
  FINANCE_MERCHANT_MANAGER = 'financeMerchantManager',
  FINANCE_MERCHANT_ADMIN = 'financeMerchantAdmin',
  ADMIN = 'admin',
  GUEST = 'guest'
}

export enum FinanceMerchantRoles {
  FINANCE_MERCHANT_AGENT = 'financeMerchantAgent',
  FINANCE_MERCHANT_MANAGER = 'financeMerchantManager',
  FINANCE_MERCHANT_ADMIN = 'financeMerchantAdmin'
}

export enum SalesRoles {
  SALES_AGENT = 'salesAgent',
  SALES_MANAGER = 'salesManager'
}
