import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../shared/layouts/default/default.layout';

const CustomersListContainer = React.lazy(
  () => import('../../containers/customers/customers-list/customers-list.container')
);

const CustomerDetailsContainer = React.lazy(
  () => import('../../containers/customers/customers-details/customers-details.container')
);

const CustomerAddContainer = React.lazy(
  () => import('../../containers/customers/customers-add/customers-add.container')
);

const CustomerEditContainer = React.lazy(
  () => import('../../containers/customers/customers-edit/customers-edit.container')
);

const CustomerFinanceApplicationDetailsContainer = React.lazy(
  () =>
    import(
      '../../containers/finance-applications/application-details/finance-applications-details.container'
    )
);

const CustomerFinanceApplicationAddContainer = React.lazy(
  () =>
    import(
      '../../containers/finance-applications/application-add/finance-applications-add.container'
    )
);

const CustomerFinanceApplicationEditContainer = React.lazy(
  () =>
    import(
      '../../containers/finance-applications/application-edit/finance-application.edit.component'
    )
);

export const CustomersRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={(APP_ROUTES.CUSTOMERS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH} />
  },
  {
    path: (APP_ROUTES.CUSTOMERS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.FINANCE_OFFICER,
            UserRole.OPERATION_MANAGER,
            UserRole.ADMIN
          ]}
          component={CustomersListContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.CUSTOMERS_MODULE.CHILDREN as RouteConfig).DETAILS.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.FINANCE_OFFICER,
            UserRole.OPERATION_MANAGER,
            UserRole.ADMIN
          ]}
          component={CustomerDetailsContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.CUSTOMERS_MODULE.CHILDREN as RouteConfig).ADD.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.OPERATION_MANAGER,
            UserRole.ADMIN
          ]}
          component={CustomerAddContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.CUSTOMERS_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_FINANCE,
            UserRole.OPERATION_MANAGER,
            UserRole.ADMIN
          ]}
          component={CustomerEditContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.CUSTOMERS_MODULE.CHILDREN as RouteConfig).FINANCE_APPLICATION_DETAILS
      .FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_FINANCE,
            UserRole.FINANCE_OFFICER,
            UserRole.OPERATION_MANAGER,
            UserRole.ADMIN
          ]}
          component={CustomerFinanceApplicationDetailsContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.CUSTOMERS_MODULE.CHILDREN as RouteConfig).ADD_FINANCE_APPLICATION.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.OPERATION_MANAGER,
            UserRole.ADMIN
          ]}
          component={CustomerFinanceApplicationAddContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.CUSTOMERS_MODULE.CHILDREN as RouteConfig).EDIT_FINANCE_APPLICATION.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_FINANCE,
            UserRole.FINANCE_OFFICER,
            UserRole.OPERATION_MANAGER,
            UserRole.ADMIN
          ]}
          component={CustomerFinanceApplicationEditContainer}
        />
      </React.Suspense>
    )
  }
];
