import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../shared/layouts/default/default.layout';
import { UserTeams } from '../../../../shared/enums/user-teams.enum';

const LeadsListContainer = React.lazy(
  () => import('../../containers/leads-list/leads-list.container')
);

const LeadsAddContainer = React.lazy(
  () => import('../../containers/leads-add/leads-add.container')
);

const LeadDetailsContainer = React.lazy(
  () => import('../../containers/leads-details/leads-details.container')
);

const LeadDashboardContainer = React.lazy(
  () => import('../../containers/leads-dashboard/leads-dashboard.container')
);

// const ProductEditUsedAutoContainer = React.lazy(
//   () => import('../../containers/products-edit-auto/products-edit-auto.container')
// );
const LeadsEditContainer = React.lazy(
  () => import('../../containers/leads-edit/leads-edit.container')
);

export const LeadsRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={(APP_ROUTES.LEADS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH} />
  },
  {
    path: (APP_ROUTES.LEADS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.MARKETING_EXECUTIVE,
            UserRole.ADMIN
          ]}
          permittedTeamsList={[UserTeams.DIGITAL, UserTeams.E_COMMERCE]}
          component={LeadsListContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.LEADS_MODULE.CHILDREN as RouteConfig).ADD.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.MARKETING_EXECUTIVE,
            UserRole.ADMIN
          ]}
          permittedTeamsList={[UserTeams.DIGITAL, UserTeams.E_COMMERCE]}
          component={LeadsAddContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.LEADS_MODULE.CHILDREN as RouteConfig).DETAILS.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.MARKETING_EXECUTIVE,
            UserRole.ADMIN
          ]}
          permittedTeamsList={[UserTeams.DIGITAL, UserTeams.E_COMMERCE]}
          component={LeadDetailsContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.LEADS_MODULE.CHILDREN as RouteConfig).DASHBOARD.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.MARKETING_EXECUTIVE,
            UserRole.ADMIN
          ]}
          permittedTeamsList={[UserTeams.DIGITAL, UserTeams.E_COMMERCE]}
          component={LeadDashboardContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.LEADS_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.MARKETING_EXECUTIVE,
            UserRole.ADMIN
          ]}
          permittedTeamsList={[UserTeams.DIGITAL, UserTeams.E_COMMERCE]}
          component={LeadsEditContainer}
        />
      </React.Suspense>
    )
  }
];
