import { ApiResponse } from 'apisauce';

import { ErrorInfo } from '../../../interfaces/error-info.interface';
import { PUBLIC_PATH } from '../../../config/routes-config';
import { ResponseModel } from '../../../interfaces/response-model.interface';
import { SalesUsersRoles } from '../../../../Modules/SalesManagementModule/shared/enums/sales-roles.enum';
import {
  MemberTeamPerformancePayload,
  TeamMembersPayload,
  TeamPerformancePayload
} from '../../../../Modules/SalesManagementModule/shared/types/search-team-members';
import {
  CommissionSchemeModel,
  CommissionSchemeModelResponse,
  CommissionSchemePayload
} from '../../../models/commission-scheme.model';
import {
  KPIsModel,
  CreateSalesTeamModel,
  SalesTeamModelResponse,
  KPIModelResponse,
  SalesTeamKPIModel,
  SalesTeamKPIModelResponse,
  CreateSalesTeamMemberModel,
  CreateSalesTeamMemberResponse,
  TeamMemberModel,
  UpdateSalesTeamMemberModel,
  TeamMembersTableModel,
  SalesTeamModel,
  TeamPerformanceModel,
  RecoverMemberModel,
  MemberCommissionResponse
} from '../../../models/teams.model';

export default {
  // Functions return fixtures

  getAllKPIs: (): Promise<ApiResponse<ResponseModel<KPIModelResponse>, ErrorInfo>> => {
    const kpis = fetch(PUBLIC_PATH + `mocks/get-salesKpi/default.json`);
    return kpis.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<KPIModelResponse>, ErrorInfo>;
    });
  },

  createKpi: (data: KPIsModel): Promise<ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>> => {
    const kpi = fetch(PUBLIC_PATH + `mocks/post-salesKpi/default.json`);
    return kpi.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>;
    });
  },

  updateSalesTeamKPI: (
    teamKPIvalues: SalesTeamKPIModel
  ): Promise<ApiResponse<ResponseModel<SalesTeamKPIModelResponse>, ErrorInfo>> => {
    const salesTeamKpi = fetch(PUBLIC_PATH + `mocks/post-salesTeamKpi/default.json`);
    return salesTeamKpi.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<SalesTeamKPIModelResponse>, ErrorInfo>;
    });
  },

  getSalesTeamKPI: (
    teamId: string,
    kpiId: string
  ): Promise<ApiResponse<ResponseModel<SalesTeamKPIModelResponse>, ErrorInfo>> => {
    const salesTeamKpi = fetch(PUBLIC_PATH + `mocks/get-salesTeamKpi/default.json`);
    return salesTeamKpi.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<SalesTeamKPIModelResponse>, ErrorInfo>;
    });
  },

  getAllSalesTeams: (): Promise<ApiResponse<ResponseModel<SalesTeamModelResponse>, ErrorInfo>> => {
    const kpis = fetch(PUBLIC_PATH + `mocks/get-salesTeam/default.json`);
    return kpis.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<SalesTeamModelResponse>, ErrorInfo>;
    });
  },

  archiveSalesTeam: (
    teamId: string
  ): Promise<ApiResponse<ResponseModel<{ message: string }>, ErrorInfo>> => {
    const response = fetch(PUBLIC_PATH + `mocks/put-salesTeam_archive/default.json`);
    return response.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{ message: string }>, ErrorInfo>;
    });
  },

  recoverSalesTeam: (teamId: string): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> => {
    const team = fetch(PUBLIC_PATH + `mocks/put-salesTeam_recover/default.json`);
    return team.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<any>, ErrorInfo>;
    });
  },

  deleteSalesTeam: (teamId: string): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> => {
    const team = fetch(PUBLIC_PATH + `mocks/delete-salesTeam/default.json`);
    return team.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<any>, ErrorInfo>;
    });
  },

  getAllArchiveSalesTeams: (): Promise<ApiResponse<ResponseModel<SalesTeamModel[]>, ErrorInfo>> => {
    const kpis = fetch(PUBLIC_PATH + `mocks/get-salesTeam_archive/default.json`);
    return kpis.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<SalesTeamModel[]>, ErrorInfo>;
    });
  },

  createSalesTeam: (
    data: CreateSalesTeamModel
  ): Promise<ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>> => {
    const team = fetch(PUBLIC_PATH + `mocks/post-salesTeam/default.json`);
    return team.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>;
    });
  },

  updateSalesTeam: (
    data: CreateSalesTeamModel
  ): Promise<ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>> => {
    const team = fetch(PUBLIC_PATH + `mocks/put-salesTeam/default.json`);
    return team.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>;
    });
  },

  createSalesTeamMember: (
    teamMember: CreateSalesTeamMemberModel
  ): Promise<ApiResponse<ResponseModel<CreateSalesTeamMemberResponse>, ErrorInfo>> => {
    const member = fetch(PUBLIC_PATH + `mocks/post-salesTeamMember/default.json`);
    return member.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<CreateSalesTeamMemberResponse>, ErrorInfo>;
    });
  },

  updateSalesTeamMember: (
    teamMember: UpdateSalesTeamMemberModel
  ): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> => {
    const member = fetch(PUBLIC_PATH + `mocks/post-salesTeamMember/default.json`);
    return member.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<any>, ErrorInfo>;
    });
  },

  searchTeamMembers: (
    payload: TeamMembersPayload
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>> => {
    const member = fetch(PUBLIC_PATH + `mocks/post-salesTeamMember_search/default.json`);
    return member.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>;
    });
  },

  getSalesTeamMemberDetailsById: (
    id: string
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel>, ErrorInfo>> => {
    const member = fetch(PUBLIC_PATH + `mocks/get-salesTeamMember/default.json`);
    return member.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<TeamMemberModel>, ErrorInfo>;
    });
  },

  getSingleUserTree: (
    userId: string
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel>, ErrorInfo>> => {
    const tree = fetch(PUBLIC_PATH + `mocks/get-salesTeamMember_detailed/default.json`);
    return tree.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<TeamMemberModel>, ErrorInfo>;
    });
  },

  getTeamTree: (
    teamId: string
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>> => {
    const tree = fetch(PUBLIC_PATH + `mocks/get-salesTeamMember_team_detailed/default.json`);
    return tree.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>;
    });
  },

  archiveSalesTeamMember: (
    memberId: string
  ): Promise<ApiResponse<ResponseModel<{ message: string }>, ErrorInfo>> => {
    const response = fetch(PUBLIC_PATH + `mocks/put-salesTeamMember_archive/default.json`);
    return response.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{ message: string }>, ErrorInfo>;
    });
  },

  recoverSalesTeamMember: (
    payload: RecoverMemberModel
  ): Promise<ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>> => {
    const member = fetch(PUBLIC_PATH + `mocks/put-salesTeamMember_recover/default.json`);
    return member.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>;
    });
  },

  deleteSalesTeamMember: (
    memberId: string
  ): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> => {
    const member = fetch(PUBLIC_PATH + `mocks/delete-salesTeamMember_archived/default.json`);
    return member.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<any>, ErrorInfo>;
    });
  },

  searchArchivedTeamMembers: (
    payload: TeamMembersPayload
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>> => {
    const member = fetch(PUBLIC_PATH + `mocks/post-salesTeamMember_archive_search/default.json`);
    return member.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>;
    });
  },

  getArchiveTeamTree: (
    teamId: string
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>> => {
    const tree = fetch(
      PUBLIC_PATH + `mocks/get-salesTeamMember_team_archived_detailed/default.json`
    );
    return tree.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>;
    });
  },

  createOrUpdateCommissions: (
    payload: CommissionSchemePayload
  ): Promise<ApiResponse<ResponseModel<CommissionSchemeModel[]>, ErrorInfo>> => {
    const tree = fetch(PUBLIC_PATH + `mocks/put-salesCommission/default.json`);
    return tree.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<CommissionSchemeModel[]>, ErrorInfo>;
    });
  },

  getCommissionsByRole: (
    roleName: SalesUsersRoles
  ): Promise<ApiResponse<ResponseModel<CommissionSchemeModelResponse>, ErrorInfo>> => {
    const kpis = fetch(PUBLIC_PATH + `mocks/get-salesCommission_role/default.json`);
    return kpis.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<CommissionSchemeModelResponse>, ErrorInfo>;
    });
  },

  searchTeamMembersCommissions: (
    payload: TeamMembersPayload
  ): Promise<ApiResponse<ResponseModel<TeamMembersTableModel[]>, ErrorInfo>> => {
    const member = fetch(PUBLIC_PATH + `mocks/post-salesTeamCommission_search/default.json`);
    return member.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<TeamMembersTableModel[]>, ErrorInfo>;
    });
  },

  getMemeberCommission: (
    memberId: string
  ): Promise<ApiResponse<ResponseModel<MemberCommissionResponse>, ErrorInfo>> => {
    const kpis = fetch(PUBLIC_PATH + `mocks/get-salesTeamCommission_commission_user/default.json`);
    return kpis.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<MemberCommissionResponse>, ErrorInfo>;
    });
  },

  getTeamPerformance: (
    payload: TeamPerformancePayload
  ): Promise<ApiResponse<ResponseModel<{ personal: TeamPerformanceModel[] }>, ErrorInfo>> => {
    const kpis = fetch(PUBLIC_PATH + `mocks/post-salesTeamCommission_performance/default.json`);
    return kpis.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{ personal: TeamPerformanceModel[] }>, ErrorInfo>;
    });
  },

  getAllTeamsPerformance: (
    payload: TeamPerformancePayload
  ): Promise<ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>> => {
    const kpis = fetch(
      PUBLIC_PATH + `mocks/post-salesTeamCommission_team_performance/default.json`
    );
    return kpis.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>;
    });
  },

  getUserTeamPerformance: (
    payload: MemberTeamPerformancePayload
  ): Promise<ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>> => {
    const kpis = fetch(
      PUBLIC_PATH + `mocks/post-salesTeamCommission_user_performance/default.json`
    );
    return kpis.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>;
    });
  },

  getArchiveTeamPerformance: (
    teamId: string
  ): Promise<ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>> => {
    const kpis = fetch(
      PUBLIC_PATH + `mocks/post-salesTeamCommission_archive_performance/default.json`
    );
    return kpis.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>;
    });
  }
};
