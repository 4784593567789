import { KPIMetricType, KPIsModel } from '../../models/teams.model';
import { KPIsUIModel } from '../../ui-models/teams.ui-model';

function mapToModel(kpi: KPIsUIModel): KPIsModel {
  return {
    ...(kpi.id && { id: kpi.id }),
    ...(kpi.name && { name: kpi.name }),
    ...(kpi.metricType?.id && { metricType: kpi.metricType.id as KPIMetricType }),
    ...(kpi.module && { module: kpi.module }),
    ...(kpi.color && { color: kpi.color })
  };
}

function mapToUi(kpi: KPIsModel[]): KPIsUIModel[] {
  return kpi.map((kpi) => ({
    id: kpi.id,
    name: kpi.name,
    metricType: { id: kpi.metricType ?? '', label: kpi.metricType ?? '' },
    module: kpi.module,
    color: kpi.color
  }));
}

const kpiMapper = { mapToModel, mapToUi };

export default kpiMapper;
