import { SalesUsersRoles } from '../../../Modules/SalesManagementModule/shared/enums/sales-roles.enum';
import { UpdateSalesTeamMemberModel } from '../../models/teams.model';
import { CreateSalesTeamMemberUIModel } from '../../ui-models/teams.ui-model';

function mapToModel(teamMember: CreateSalesTeamMemberUIModel): UpdateSalesTeamMemberModel {
  return {
    id: teamMember.userId,
    role: teamMember.role.id as SalesUsersRoles,
    team: teamMember.team?.id ?? '',
    reportsTo: teamMember.reportsTo?.id ?? null,
    ...(teamMember.assignees?.length && { assignees: teamMember.assignees })
  };
}

const updateSalesMemebersMapper = { mapToModel };

export default updateSalesMemebersMapper;
