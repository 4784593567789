import { TeamMemberTreeItem } from '../../../Modules/SalesManagementModule/shared/components/teams/team-members/team-members.component';
import { TeamMemberModel } from '../../models/teams.model';

function mapToUi(teamMember: TeamMemberModel[] | TeamMemberModel): TeamMemberTreeItem[] {
  if (Array.isArray(teamMember)) {
    return teamMember.map((user) => ({
      id: `${user.id}`,
      label: user.user?.name ?? '',
      image: user.user?.image ?? '',
      role: user.role,
      children: user.children ? mapToUi(user.children) : []
    }));
  } else {
    return [
      {
        id: `${teamMember.id}`,
        label: teamMember.user?.name ?? '',
        image: teamMember.user?.image ?? '',
        role: teamMember.role,
        children: teamMember.children ? mapToUi(teamMember.children) : []
      }
    ];
  }
}

const salesMemebersTreeMapper = { mapToUi };

export default salesMemebersTreeMapper;
