import moment from 'moment-timezone';

import { MemberCommissionResponse } from '../../models/teams.model';
import { MemberCommissionTableUIModel } from '../../ui-models/teams.ui-model';
import {
  getPrimaryColor,
  getSecondaryColor
} from '../../components/core-ui/color-picker/color-picker.component';

function mapToUi(
  teamMember: MemberCommissionResponse,
  teamsList: { value: string; label: string }[]
): MemberCommissionTableUIModel[] {
  const groupedByDateRoleTeam: { [key: string]: MemberCommissionTableUIModel } = {};

  teamMember?.commissions?.forEach((commission) => {
    const {
      month,
      year,
      role,
      team,
      kpi,
      monthlyTarget,
      teamMonthlyTarget,
      commission: commissionStatus
    } = commission;

    const groupId = `${month}_${year}_${role}_${team}`;

    if (!groupedByDateRoleTeam[groupId]) {
      groupedByDateRoleTeam[groupId] = {
        id: groupId,
        date: moment({ year: year, month: month - 1 }).format('MMM YYYY'),
        role,
        commission: commissionStatus,
        performance: [],
        teamPerformance: [],
        team: teamsList.find((t) => t.value === team)?.label ?? ''
      };
    }

    groupedByDateRoleTeam[groupId].performance?.push({
      kpiName: kpi?.name ?? '',
      metricType: kpi?.metricType ?? '',
      value: commission.totalAchievedTargetCount,
      maxValue: monthlyTarget,
      primaryColor: getPrimaryColor(kpi?.color ?? '') ?? '',
      secondryColor: getSecondaryColor(kpi?.color ?? '') ?? ''
    });

    groupedByDateRoleTeam[groupId].teamPerformance?.push({
      kpiName: kpi?.name ?? '',
      metricType: kpi?.metricType ?? '',
      value: commission.teamTotalAchievedTargetCount,
      maxValue: teamMonthlyTarget,
      primaryColor: getPrimaryColor(kpi?.color ?? '') ?? '',
      secondryColor: getSecondaryColor(kpi?.color ?? '') ?? ''
    });
  });

  return Object.values(groupedByDateRoleTeam).sort((a, b) => {
    return moment(b.date, 'MMM YYYY').valueOf() - moment(a.date, 'MMM YYYY').valueOf();
  });
}

const salesMemberCommissionTableMapper = { mapToUi };

export default salesMemberCommissionTableMapper;
