import { TeamMemberModel } from '../../models/teams.model';
import { TeamUIModel } from '../../ui-models/teams.ui-model';

function mapToUi(teamMember: TeamMemberModel[]): TeamUIModel[] {
  return teamMember.map((user) => ({
    id: user.id,
    role: user.role,
    team: user.team.name,
    teamId: user.team.id,
    user: { id: user.user?.id, name: user.user?.name, url: user.user?.image },
    reportsTo: {
      id: user.reportsToUser?.id ?? '',
      name: user.reportsToUser?.name ?? '',
      url: user.reportsToUser?.image ?? ''
    }
  }));
}

const salesMemebersMapper = { mapToUi };

export default salesMemebersMapper;
