import { styled } from '@mui/material/styles';

export interface StyledIconProps {
  primaryColor: string;
  secondryColor?: string;
}

const BpIcon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'primaryColor' && prop !== 'secondryColor'
})<StyledIconProps>(({ theme, primaryColor, secondryColor }) => {
  return {
    borderRadius: '50%',
    width: 26,
    height: 26,
    backgroundImage: `linear-gradient(to right,${primaryColor},${
      secondryColor ? secondryColor : primaryColor
    })`,
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  };
});

const BpCheckedIcon = styled(BpIcon, {
  shouldForwardProp: (prop) => prop !== 'primaryColor' && prop !== 'secondryColor'
})<StyledIconProps>(({ theme, primaryColor, secondryColor }) => ({
  backgroundImage: `linear-gradient(to right,${primaryColor},${
    secondryColor ? secondryColor : primaryColor
  })`,
  '&::before': {
    display: 'block',
    width: 26,
    height: 26,
    content: '""'
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 5,
    left: 5,
    width: 34,
    height: 34,
    border: '2px solid #137cbd',
    borderRadius: '50%'
  }
}));

export { BpIcon, BpCheckedIcon };
