import { handleActions } from 'redux-actions';
import * as Actions from '../../constants/actions';
import { UserModel } from '../../../models/user.model';
import { ErrorInfo } from '../../../interfaces/error-info.interface';
import { RootState } from '..';
import { UserRole } from '../../../enums/user-role.enum';

export interface AuthStoreState extends UserModel {
  fetching: boolean;
  error: ErrorInfo | null;
  accessToken: string;
  tokenExpiresIn: number;
  permissions: string[];
  refreshToken: string;
}

export const initialState: AuthStoreState = {
  error: null,
  fetching: false,
  tokenExpiresIn: 0,
  userId: '',
  email: '',
  image: '',
  permissions: [],
  accessToken: '',
  refreshToken: '',
  name: '',
  role: [UserRole.GUEST],
  team: undefined
};

export const AuthSelectors = {
  isLoggedIn: (state: RootState) => state.auth.accessToken
};

export default handleActions<AuthStoreState>(
  {
    [Actions.REGISTER_USER_REQUEST]: (state: AuthStoreState, action: any) => {
      const { email } = action.payload;
      return {
        ...state,
        email,
        error: null,
        fetching: true
      };
    },
    [Actions.REGISTER_USER_FAILURE]: (state: AuthStoreState, action: any) => {
      return {
        ...state,
        ...action.payload,
        fetching: false,
        userLoaded: true
      };
    },
    [Actions.USER_DATA_SUCCESS]: (state: AuthStoreState, action: any) => {
      const {
        email,
        userId,
        accessToken,
        refreshToken,
        tokenExpiresIn,
        role,
        team,
        permissions,
        name,
        image
      } = action.payload;
      return {
        ...state,
        email,
        userId,
        image,
        accessToken,
        refreshToken,
        tokenExpiresIn,
        role,
        team,
        permissions,
        fetching: false,
        userLoaded: true,
        name
      };
    },
    [Actions.USER_TEAM_DATA_SUCCESS]: (state: AuthStoreState, action: any) => {
      const { team, permissions } = action.payload;
      return {
        ...state,
        team
      };
    },
    [Actions.USER_NOTIFICATION_SUCCESS]: (state: AuthStoreState, action: any) => {
      return {
        ...state,
        userNotifications: action.payload
      };
    },
    [Actions.USER_DATA_CLEANUP]: () => {
      return initialState;
    }
  },
  initialState
);
