import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../shared/config/routes-config';
import { UserRole } from '../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../shared/layouts/default/default.layout';

const HomeContainer = React.lazy(() => import('../../containers/home/home.container'));

export const DashboardRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={(APP_ROUTES.DASHBOARD_MODULE.CHILDREN as RouteConfig).HOME.FULL_PATH} />
  },
  {
    path: (APP_ROUTES.DASHBOARD_MODULE.CHILDREN as RouteConfig).HOME.FULL_PATH,
    element: (
      // <React.Suspense fallback={<div>Loading...</div>}>
      <DefaultLayout
        authenticationRequired={true}
        permittedList={[
          UserRole.SALES_AGENT,
          UserRole.SALES_MANAGER,
          UserRole.MARKETING_EXECUTIVE,
          UserRole.E_COMMERCE_FINANCE,
          UserRole.E_COMMERCE_AUTO,
          UserRole.E_COMMERCE_MANAGER,
          UserRole.FINANCE_OFFICER,
          UserRole.OPERATION_MANAGER,
          UserRole.ADMIN
        ]}
        component={HomeContainer}
      />
      // </React.Suspense>
    )
  }
];
