import { TeamUIModel } from '../../ui-models/teams.ui-model';
import { TeamMembersTableModel } from '../../models/teams.model';
import {
  getPrimaryColor,
  getSecondaryColor
} from '../../components/core-ui/color-picker/color-picker.component';

function mapToUi(teamMember: TeamMembersTableModel[]): TeamUIModel[] {
  return (
    teamMember?.map((user) => ({
      id: user.id,
      role: user.role,
      team: user.team.name,
      teamId: user.team.id,
      user: { id: user.user?.id, name: user.user?.name, url: user.user?.image },
      reportsTo: {
        id: user.reportToUser?.id ?? '',
        name: user.reportToUser?.name ?? '',
        url: user.reportToUser?.image ?? ''
      },
      commission: user.commission,
      performance: user.kpis.map((kpi) => ({
        kpiName: kpi.kpi.name ?? '',
        metricType: kpi.kpi.metricType ?? '',
        value: kpi.totalAchievedTargetCount,
        maxValue: kpi.monthlyTarget,
        primaryColor: getPrimaryColor(kpi.kpi.color ?? '') ?? '',
        secondryColor: getSecondaryColor(kpi.kpi.color ?? '') ?? ''
      }))
    })) ?? []
  );
}

const salesMemebersTableMapper = { mapToUi };

export default salesMemebersTableMapper;
