import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import DefaultLayout from '../../../../shared/layouts/default/default.layout';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';

const FinanceApplicationsListContainer = React.lazy(
  () => import('../../containers/finance-applications-list/finance-applications-list.component')
);

const FinanceApplicationsDashboardContainer = React.lazy(
  () =>
    import('../../containers/finance-application-dashboard/finance-application-dashboard.container')
);

const FinanceApplicationsDetailsContainer = React.lazy(
  () =>
    import('../../containers/finance-application-details/finance-applications-details.container')
);

const FinanceApplicationsAddContainer = React.lazy(
  () => import('../../containers/finance-application-add/finance-applications-add.container')
);

const FinanceApplicationsEditContainer = React.lazy(
  () => import('../../containers/finance-application-edit/finance-application.edit.component')
);

export const FinanceApplicationsRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: (
      <Navigate
        to={(APP_ROUTES.FINANCE_APPLICATIONS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH}
      />
    )
  },
  {
    path: (APP_ROUTES.FINANCE_APPLICATIONS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_FINANCE,
            UserRole.FINANCE_OFFICER,
            UserRole.OPERATION_MANAGER,
            UserRole.ADMIN
          ]}
          component={FinanceApplicationsListContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.FINANCE_APPLICATIONS_MODULE.CHILDREN as RouteConfig).DETAILS.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_FINANCE,
            UserRole.FINANCE_OFFICER,
            UserRole.OPERATION_MANAGER,
            UserRole.ADMIN
          ]}
          component={FinanceApplicationsDetailsContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.FINANCE_APPLICATIONS_MODULE.CHILDREN as RouteConfig).ADD.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_FINANCE,
            UserRole.FINANCE_OFFICER,
            UserRole.OPERATION_MANAGER,
            UserRole.ADMIN
          ]}
          component={FinanceApplicationsAddContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.FINANCE_APPLICATIONS_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.SALES_AGENT,
            UserRole.SALES_MANAGER,
            UserRole.E_COMMERCE_FINANCE,
            UserRole.FINANCE_OFFICER,
            UserRole.OPERATION_MANAGER,
            UserRole.ADMIN
          ]}
          component={FinanceApplicationsEditContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.FINANCE_APPLICATIONS_MODULE.CHILDREN as RouteConfig).DASHBOARD.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.SALES_MANAGER, UserRole.ADMIN]}
          component={FinanceApplicationsDashboardContainer}
        />
      </React.Suspense>
    )
  }
];
