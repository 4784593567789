import { ApiResponse } from 'apisauce';

import kpiMapper from '../../../mappers/sales-managment/kpi.mapper';
import errorHelper from '../../helpers/error-helper/error-helper.service';
import salesMemebersTreeMapper from '../../../mappers/sales-managment/sales-members-tree.mapper';
import salesMemebersTableMapper from '../../../mappers/sales-managment/sales-members-table.mapper';
import createSalesMemebersMapper from '../../../mappers/sales-managment/sales-members-create.mapper';
import updateSalesMemebersMapper from '../../../mappers/sales-managment/sales-members-update.mapper';
import { ErrorInfo } from '../../../interfaces/error-info.interface';
import { ResponseModel } from '../../../interfaces/response-model.interface';
import { SalesUsersRoles } from '../../../../Modules/SalesManagementModule/shared/enums/sales-roles.enum';
import { salesManagementApi } from '../../apis/sales-management-apis/sales-management-api.service';
import {
  MemberTeamPerformancePayload,
  TeamMembersPayload,
  TeamPerformancePayload
} from '../../../../Modules/SalesManagementModule/shared/types/search-team-members';
import { TeamMemberTreeItem } from '../../../../Modules/SalesManagementModule/shared/components/teams/team-members/team-members.component';
import {
  KPIsUIModel,
  TeamUIModel,
  KpiUIModelResponse,
  CreateSalesTeamMemberUIModel,
  MemberCommissionTableUIModel
} from '../../../ui-models/teams.ui-model';
import {
  CommissionSchemeModel,
  CommissionSchemeModelResponse,
  CommissionSchemePayload
} from '../../../models/commission-scheme.model';
import {
  CreateSalesTeamModel,
  SalesTeamModelResponse,
  KPIModelResponse,
  SalesTeamKPIModel,
  SalesTeamKPIModelResponse,
  CreateSalesTeamMemberResponse,
  TeamMemberModel,
  SalesTeamModel,
  TeamPerformanceModel,
  RecoverMemberModel,
  TeamMembersTableModel,
  MemberCommissionResponse
} from '../../../models/teams.model';
import salesMemebersMapper from '../../../mappers/sales-managment/sales-members.mapper';
import salesMemberCommissionTableMapper from '../../../mappers/sales-managment/sales-member-commissions-table.mapper';

export async function getAllKPIs(containerName: string): Promise<KpiUIModelResponse | ErrorInfo> {
  return salesManagementApi
    .getAllKPIs()
    .then((response: ApiResponse<ResponseModel<KPIModelResponse>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = kpiMapper.mapToUi(data.data.kpis);
          return { ...data.data, kpis: mappedData };
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getAllKPIs as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getAllKPIs as any).name
        );
        return error;
      }
    });
}

export async function createKpi(
  kpi: KPIsUIModel,
  containerName: string
): Promise<ResponseModel<{ id: string }> | ErrorInfo> {
  const mappedData = kpiMapper.mapToModel(kpi);
  return salesManagementApi
    .createKpi(mappedData)
    .then((response: ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.createKpi as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.createKpi as any).name
        );
        return error;
      }
    });
}

export async function getAllSalesTeams(
  containerName: string
): Promise<SalesTeamModelResponse | ErrorInfo> {
  return salesManagementApi
    .getAllSalesTeams()
    .then((response: ApiResponse<ResponseModel<SalesTeamModelResponse>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data.data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getAllSalesTeams as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getAllSalesTeams as any).name
        );
        return error;
      }
    });
}

export async function archiveSalesTeam(
  teamId: string,
  containerName: string
): Promise<{ message: string } | ErrorInfo> {
  return salesManagementApi
    .archiveSalesTeam(teamId)
    .then((response: ApiResponse<ResponseModel<{ message: string }>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data.data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.archiveSalesTeam as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.archiveSalesTeam as any).displayName
        );

        return error;
      }
    });
}

export async function recoverSalesTeam(
  id: string,
  containerName: string
): Promise<{ message: string } | ErrorInfo> {
  return salesManagementApi
    .recoverSalesTeam(id)
    .then((response: ApiResponse<{ message: string }, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.recoverSalesTeam as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.recoverSalesTeam as any).displayName
        );

        return error;
      }
    });
}

export async function deleteSalesTeam(
  id: string,
  containerName: string
): Promise<{ message: string } | ErrorInfo> {
  return salesManagementApi
    .deleteSalesTeam(id)
    .then((response: ApiResponse<{ message: string }, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.deleteSalesTeam as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.deleteSalesTeam as any).displayName
        );

        return error;
      }
    });
}

export async function getAllArchiveSalesTeams(
  containerName: string
): Promise<SalesTeamModel[] | ErrorInfo> {
  return salesManagementApi
    .getAllArchiveSalesTeams()
    .then((response: ApiResponse<ResponseModel<SalesTeamModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data.data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getAllArchiveSalesTeams as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getAllArchiveSalesTeams as any).displayName
        );

        return error;
      }
    });
}

export async function createSalesTeam(
  team: CreateSalesTeamModel,
  containerName: string
): Promise<ResponseModel<{ id: string }> | ErrorInfo> {
  return salesManagementApi
    .createSalesTeam(team)
    .then((response: ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.createSalesTeam as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.createSalesTeam as any).name
        );
        return error;
      }
    });
}

export async function updateSalesTeam(
  team: CreateSalesTeamModel,
  containerName: string
): Promise<ResponseModel<{ id: string }> | ErrorInfo> {
  return salesManagementApi
    .updateSalesTeam(team)
    .then((response: ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.updateSalesTeam as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.updateSalesTeam as any).name
        );
        return error;
      }
    });
}

export async function updateSalesTeamKPI(
  teamKPIvalues: SalesTeamKPIModel,
  containerName: string
): Promise<ResponseModel<SalesTeamKPIModelResponse> | ErrorInfo> {
  return salesManagementApi
    .updateSalesTeamKPI(teamKPIvalues)
    .then((response: ApiResponse<ResponseModel<SalesTeamKPIModelResponse>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.updateSalesTeamKPI as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.updateSalesTeamKPI as any).name
        );
        return error;
      }
    });
}

export async function getSalesTeamKPI(
  teamId: string,
  kpiId: string,
  containerName: string
): Promise<SalesTeamKPIModelResponse | ErrorInfo> {
  return salesManagementApi
    .getSalesTeamKPI(teamId, kpiId)
    .then((response: ApiResponse<ResponseModel<SalesTeamKPIModelResponse>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data.data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getSalesTeamKPI as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getSalesTeamKPI as any).name
        );
        return error;
      }
    });
}

export async function createSalesTeamMember(
  teamMember: CreateSalesTeamMemberUIModel,
  containerName: string
): Promise<ResponseModel<CreateSalesTeamMemberResponse> | ErrorInfo> {
  const mappedData = createSalesMemebersMapper.mapToModel(teamMember);
  return salesManagementApi
    .createSalesTeamMember(mappedData)
    .then((response: ApiResponse<ResponseModel<CreateSalesTeamMemberResponse>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.createSalesTeamMember as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.createSalesTeamMember as any).name
        );
        return error;
      }
    });
}

export async function updateSalesTeamMember(
  teamMember: CreateSalesTeamMemberUIModel,
  containerName: string
): Promise<ResponseModel<CreateSalesTeamMemberResponse> | ErrorInfo> {
  const mappedData = updateSalesMemebersMapper.mapToModel(teamMember);
  return salesManagementApi
    .updateSalesTeamMember(mappedData)
    .then((response: ApiResponse<ResponseModel<CreateSalesTeamMemberResponse>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.updateSalesTeamMember as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.updateSalesTeamMember as any).name
        );
        return error;
      }
    });
}

export async function searchTeamMembers(
  payload: TeamMembersPayload,
  containerName: string
): Promise<ResponseModel<TeamUIModel[]> | ErrorInfo> {
  return salesManagementApi
    .searchTeamMembers(payload)
    .then((response: ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = salesMemebersMapper.mapToUi(data.data);
          return {
            status: data.status,
            data: mappedData,
            message: data.message,
            total_count: data.total_count
          };
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.searchTeamMembers as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.searchTeamMembers as any).name
        );

        return error;
      }
    });
}

export async function getSalesTeamMemberDetailsById(
  id: string,
  containerName: string
): Promise<ResponseModel<TeamMemberModel> | ErrorInfo> {
  return salesManagementApi
    .getSalesTeamMemberDetailsById(id)
    .then((response: ApiResponse<ResponseModel<TeamMemberModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getSalesTeamMemberDetailsById as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getSalesTeamMemberDetailsById as any).name
        );

        return error;
      }
    });
}

export async function getSingleUserTree(
  memberId: string,
  containerName: string
): Promise<TeamMemberTreeItem[] | ErrorInfo> {
  return salesManagementApi
    .getSingleUserTree(memberId)
    .then((response: ApiResponse<ResponseModel<TeamMemberModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = salesMemebersTreeMapper.mapToUi(data.data);
          return mappedData;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getSingleUserTree as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getSingleUserTree as any).name
        );

        return error;
      }
    });
}

export async function getTeamTree(
  teamId: string,
  containerName: string
): Promise<TeamMemberTreeItem[] | ErrorInfo> {
  return salesManagementApi
    .getTeamTree(teamId)
    .then((response: ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = salesMemebersTreeMapper.mapToUi(data.data);
          return mappedData;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getTeamTree as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getTeamTree as any).name
        );

        return error;
      }
    });
}

export async function archiveSalesTeamMember(
  memberId: string,
  containerName: string
): Promise<{ message: string } | ErrorInfo> {
  return salesManagementApi
    .archiveSalesTeamMember(memberId)
    .then((response: ApiResponse<ResponseModel<{ message: string }>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data.data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.archiveSalesTeamMember as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.archiveSalesTeamMember as any).displayName
        );

        return error;
      }
    });
}

export async function recoverSalesTeamMember(
  payload: RecoverMemberModel,
  containerName: string
): Promise<{ message: string } | ErrorInfo> {
  return salesManagementApi
    .recoverSalesTeamMember(payload)
    .then((response: ApiResponse<{ message: string }, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.recoverSalesTeamMember as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.recoverSalesTeamMember as any).displayName
        );

        return error;
      }
    });
}

export async function deleteSalesTeamMember(
  id: string,
  containerName: string
): Promise<{ message: string } | ErrorInfo> {
  return salesManagementApi
    .deleteSalesTeamMember(id)
    .then((response: ApiResponse<{ message: string }, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.deleteSalesTeamMember as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.deleteSalesTeamMember as any).displayName
        );

        return error;
      }
    });
}

export async function searchArchivedTeamMembers(
  payload: TeamMembersPayload,
  containerName: string
): Promise<ResponseModel<TeamUIModel[]> | ErrorInfo> {
  return salesManagementApi
    .searchArchivedTeamMembers(payload)
    .then((response: ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = salesMemebersMapper.mapToUi(data.data);
          return {
            status: data.status,
            data: mappedData,
            message: data.message,
            total_count: data.total_count
          };
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.searchArchivedTeamMembers as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.searchArchivedTeamMembers as any).name
        );

        return error;
      }
    });
}

export async function getArchiveTeamTree(
  teamId: string,
  containerName: string
): Promise<TeamMemberTreeItem[] | ErrorInfo> {
  return salesManagementApi
    .getArchiveTeamTree(teamId)
    .then((response: ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = salesMemebersTreeMapper.mapToUi(data.data);
          return mappedData;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getArchiveTeamTree as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getArchiveTeamTree as any).name
        );

        return error;
      }
    });
}

export async function createOrUpdateCommissions(
  payload: CommissionSchemePayload,
  containerName: string
): Promise<CommissionSchemeModel[] | ErrorInfo> {
  return salesManagementApi
    .createOrUpdateCommissions(payload)
    .then((response: ApiResponse<ResponseModel<CommissionSchemeModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data.data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.createOrUpdateCommissions as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.createOrUpdateCommissions as any).name
        );

        return error;
      }
    });
}

export async function getCommissionsByRole(
  roleName: SalesUsersRoles,
  containerName: string
): Promise<CommissionSchemeModelResponse | ErrorInfo> {
  return salesManagementApi
    .getCommissionsByRole(roleName)
    .then((response: ApiResponse<ResponseModel<CommissionSchemeModelResponse>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data.data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getCommissionsByRole as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getCommissionsByRole as any).name
        );
        return error;
      }
    });
}

export async function searchTeamMembersCommissions(
  payload: TeamMembersPayload,
  containerName: string
): Promise<ResponseModel<TeamUIModel[]> | ErrorInfo> {
  return salesManagementApi
    .searchTeamMembersCommissions(payload)
    .then((response: ApiResponse<ResponseModel<TeamMembersTableModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = salesMemebersTableMapper.mapToUi(data.data);
          return {
            status: data.status,
            data: mappedData,
            message: data.message,
            total_count: data.total_count
          };
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.searchTeamMembersCommissions as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.searchTeamMembersCommissions as any).name
        );

        return error;
      }
    });
}

export async function getMemberCommission(
  memberId: string,
  teamsList: { value: string; label: string }[],
  containerName: string
): Promise<MemberCommissionTableUIModel[] | ErrorInfo> {
  return salesManagementApi
    .getMemeberCommission(memberId)
    .then((response: ApiResponse<ResponseModel<MemberCommissionResponse>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = salesMemberCommissionTableMapper.mapToUi(data.data, teamsList);
          return mappedData;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getMemeberCommission as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getMemeberCommission as any).name
        );

        return error;
      }
    });
}

export async function getTeamPerformance(
  payload: TeamPerformancePayload,
  containerName: string
): Promise<TeamPerformanceModel[] | ErrorInfo> {
  return salesManagementApi
    .getTeamPerformance(payload)
    .then(
      (response: ApiResponse<ResponseModel<{ personal: TeamPerformanceModel[] }>, ErrorInfo>) => {
        if (response.ok) {
          const { data } = response;
          if (data) {
            return data.data?.personal ?? [];
          } else {
            const error: ErrorInfo = errorHelper(
              0,
              '',
              containerName,
              (salesManagementApi.getTeamPerformance as any).name
            );
            return error;
          }
        } else {
          const error: ErrorInfo = errorHelper(
            Number(response.status),
            response.data && response.data.errorCode
              ? String(response.data.errorCode || '')
              : response.problem,
            containerName,
            (salesManagementApi.getTeamPerformance as any).name
          );

          return error;
        }
      }
    );
}

export async function getAllTeamsPerformance(
  payload: TeamPerformancePayload,
  containerName: string
): Promise<TeamPerformanceModel[] | ErrorInfo> {
  return salesManagementApi
    .getAllTeamsPerformance(payload)
    .then((response: ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data.data ?? [];
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getAllTeamsPerformance as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getAllTeamsPerformance as any).name
        );

        return error;
      }
    });
}

export async function getUserTeamPerformance(
  payload: MemberTeamPerformancePayload,
  containerName: string
): Promise<TeamPerformanceModel[] | ErrorInfo> {
  return salesManagementApi
    .getUserTeamPerformance(payload)
    .then((response: ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data.data ?? [];
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getUserTeamPerformance as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getUserTeamPerformance as any).name
        );

        return error;
      }
    });
}

export async function getArchiveTeamPerformance(
  teamId: string,
  containerName: string
): Promise<TeamPerformanceModel[] | ErrorInfo> {
  return salesManagementApi
    .getArchiveTeamPerformance(teamId)
    .then((response: ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data.data ?? [];
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (salesManagementApi.getArchiveTeamPerformance as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (salesManagementApi.getArchiveTeamPerformance as any).name
        );

        return error;
      }
    });
}
