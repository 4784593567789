import _ from 'lodash';
import {
  getMotorTypesLang,
  getTransmissionTypesLang,
  MotorTypes,
  TransmissionTypes
} from '../../../enums/product-details-form.enum';
import { FullProductDetailsModel } from '../../../models/auto-product.model';
import { FullProductDetailsUiModel } from '../../../ui-models/auto-products.ui-model';
import productDocumentsMapper from './product-documents.mapper';

function mapToModel(productDetails: FullProductDetailsUiModel): FullProductDetailsModel {
  return {
    id: productDetails.id,
    companyId: productDetails.companyId?.id ?? '',
    branchId: productDetails.branchId?.id ?? '',
    productType: productDetails.productType,
    ...(productDetails.automotiveTypeId?.id && {
      automotiveTypeId: productDetails.automotiveTypeId.id
    }),
    ...(productDetails.bodyTypeId?.id && { bodyTypeId: productDetails.bodyTypeId.id }),
    ...(productDetails.motorType?.id && {
      motorType: getMotorTypesLang(productDetails.motorType.id as MotorTypes)
    }),
    ...(productDetails.transmissionType && {
      transmissionType: getTransmissionTypesLang(
        productDetails.transmissionType as TransmissionTypes
      )
    }),
    ...(productDetails.carBrandId?.id && { carBrandId: productDetails.carBrandId.id }),
    ...(productDetails.carModelId?.id && { carModelId: productDetails.carModelId.id }),
    ...(productDetails.carCategoryId?.id && { carCategoryId: productDetails.carCategoryId.id }),
    ...(productDetails.colorId?.id && { colorId: productDetails.colorId.id }),
    ...(productDetails.manufactureYear && {
      manufactureYear: Number(productDetails.manufactureYear)
    }),
    ...(productDetails.carPrice && { carPrice: Number(productDetails.carPrice) }),
    ...(productDetails.km && { km: Number(productDetails.km) }),
    ...(productDetails.noOfDoors?.id && { noOfDoors: Number(productDetails.noOfDoors.id) }),
    ...(productDetails.engineCapacity?.id && {
      engineCapacity: Number(productDetails.engineCapacity.id)
    }),
    ...(productDetails.carChassis !== undefined && { carChassis: productDetails.carChassis }),
    ...(productDetails.motorNumber !== undefined && { motorNumber: productDetails.motorNumber }),
    ...((productDetails.carDescriptionEn !== undefined ||
      productDetails.carDescriptionAr !== undefined) && {
      carDescription: {
        en: productDetails.carDescriptionEn ?? '',
        ar: productDetails.carDescriptionAr ?? ''
      }
    }),
    ...(productDetails.uploadCar360 && { uploadCar360: productDetails.uploadCar360 }),
    ...(productDetails.youtubeLinks && { youtubeLinks: productDetails.youtubeLinks }),
    ...(productDetails.isListed !== undefined && { isListed: productDetails.isListed }),
    ...(productDetails.isFeatured !== undefined && { isFeatured: productDetails.isFeatured }),
    ...(productDetails.productActiveStatus !== undefined && {
      productActiveStatus: productDetails.productActiveStatus
    }),
    ...(productDetails.numberOfCylinders?.id && {
      numberOfCylinders: Number(productDetails.numberOfCylinders.id)
    }),
    ...(productDetails.ratedTorque !== undefined && { ratedTorque: productDetails.ratedTorque }),
    ...(productDetails.acceleration !== undefined && { acceleration: productDetails.acceleration }),
    ...(productDetails.topSpeed !== undefined && { topSpeed: productDetails.topSpeed }),
    ...(productDetails.fuelType !== undefined && { fuelType: productDetails.fuelType }),
    ...(productDetails.fuelConsumption !== undefined && {
      fuelConsumption: productDetails.fuelConsumption
    }),
    ...(productDetails.fuelTankCapacity !== undefined && {
      fuelTankCapacity: productDetails.fuelTankCapacity
    }),
    ...(productDetails.bootCapacity !== undefined && { bootCapacity: productDetails.bootCapacity }),
    ...(productDetails.kerbWeight !== undefined && { kerbWeight: productDetails.kerbWeight }),
    ...(productDetails.length !== undefined && { length: productDetails.length }),
    ...(productDetails.width !== undefined && { width: productDetails.width }),
    ...(productDetails.height !== undefined && { height: productDetails.height }),
    ...(productDetails.wheelSize !== undefined && { wheelSize: productDetails.wheelSize }),
    ...(productDetails.groundClearance !== undefined && {
      groundClearance: Number(productDetails.groundClearance)
    }),
    ...(productDetails.TypeSize !== undefined && { TypeSize: productDetails.TypeSize }),
    ...(productDetails.hasPanoramicSlidingRoof !== undefined && {
      hasPanoramicSlidingRoof: productDetails.hasPanoramicSlidingRoof
    }),
    ...(productDetails.hasTintedRearWindows !== undefined && {
      hasTintedRearWindows: productDetails.hasTintedRearWindows
    }),
    ...(productDetails.hasHeatAndNoiseInsulatingAcousticGlass !== undefined && {
      hasHeatAndNoiseInsulatingAcousticGlass: productDetails.hasHeatAndNoiseInsulatingAcousticGlass
    }),
    ...(productDetails.hasNightPackage !== undefined && {
      hasNightPackage: productDetails.hasNightPackage
    }),
    ...(productDetails.hasAmgBodystyling !== undefined && {
      hasAmgBodystyling: productDetails.hasAmgBodystyling
    }),
    ...(productDetails.hasLedHighPerformanceHeadlamps !== undefined && {
      hasLedHighPerformanceHeadlamps: productDetails.hasLedHighPerformanceHeadlamps
    }),
    ...(productDetails.hasSunProtectionPackage !== undefined && {
      hasSunProtectionPackage: productDetails.hasSunProtectionPackage
    }),
    ...(productDetails.hasFoldingForBackSeats !== undefined && {
      hasFoldingForBackSeats: productDetails.hasFoldingForBackSeats
    }),
    ...(productDetails.hasMultiFunctionSteeringWheel !== undefined && {
      hasMultiFunctionSteeringWheel: productDetails.hasMultiFunctionSteeringWheel
    }),
    ...(productDetails.hasPaddleShifters !== undefined && {
      hasPaddleShifters: productDetails.hasPaddleShifters
    }),
    ...(productDetails.hasACforBackSeats !== undefined && {
      hasACforBackSeats: productDetails.hasACforBackSeats
    }),
    ...(productDetails.hasLeatherSteeringWheel !== undefined && {
      hasLeatherSteeringWheel: productDetails.hasLeatherSteeringWheel
    }),
    ...(productDetails.hasLeatherTransmission !== undefined && {
      hasLeatherTransmission: productDetails.hasLeatherTransmission
    }),
    ...(productDetails.hasBackHolder !== undefined && {
      hasBackHolder: productDetails.hasBackHolder
    }),
    ...(productDetails.hasPowerTailgate !== undefined && {
      hasPowerTailgate: productDetails.hasPowerTailgate
    }),
    ...(productDetails.hasKeylessEntry !== undefined && {
      hasKeylessEntry: productDetails.hasKeylessEntry
    }),
    ...(productDetails.hasAmbientLighting !== undefined && {
      hasAmbientLighting: productDetails.hasAmbientLighting
    }),
    ...(productDetails.hasAutoDimmingMirror !== undefined && {
      hasAutoDimmingMirror: productDetails.hasAutoDimmingMirror
    }),
    ...(productDetails.hasSteeringWheel !== undefined && {
      hasSteeringWheel: productDetails.hasSteeringWheel
    }),
    ...(productDetails.hasAirCondition !== undefined && {
      hasAirCondition: productDetails.hasAirCondition
    }),
    ...(productDetails.hasKeylessStartandStop !== undefined && {
      hasKeylessStartandStop: productDetails.hasKeylessStartandStop
    }),
    ...(productDetails.hasFrontParkingSensors !== undefined && {
      hasFrontParkingSensors: productDetails.hasFrontParkingSensors
    }),
    ...(productDetails.hasRearParkingSensors !== undefined && {
      hasRearParkingSensors: productDetails.hasRearParkingSensors
    }),
    ...(productDetails.hasRearViewCamera !== undefined && {
      hasRearViewCamera: productDetails.hasRearViewCamera
    }),
    ...(productDetails.hasPassengerSeatElectric !== undefined && {
      hasPassengerSeatElectric: productDetails.hasPassengerSeatElectric
    }),
    ...(productDetails.hasLeatherSeat !== undefined && {
      hasLeatherSeat: productDetails.hasLeatherSeat
    }),
    ...(productDetails.hasCenterLock !== undefined && {
      hasCenterLock: productDetails.hasCenterLock
    }),
    ...(productDetails.hasElectronicWindow !== undefined && {
      hasElectronicWindow: productDetails.hasElectronicWindow
    }),
    ...(productDetails.hasBackParkingSensors !== undefined && {
      hasBackParkingSensors: productDetails.hasBackParkingSensors
    }),
    ...(productDetails.hasFrontBrakingSensors !== undefined && {
      hasFrontBrakingSensors: productDetails.hasFrontBrakingSensors
    }),
    ...(productDetails.hasParkingSensorsWithParttronic !== undefined && {
      hasParkingSensorsWithParttronic: productDetails.hasParkingSensorsWithParttronic
    }),
    ...(productDetails.hasBlindSpotAssist !== undefined && {
      hasBlindSpotAssist: productDetails.hasBlindSpotAssist
    }),
    ...(productDetails.hasReversingCamera !== undefined && {
      hasReversingCamera: productDetails.hasReversingCamera
    }),
    ...(productDetails.hasBrakeAssist !== undefined && {
      hasBrakeAssist: productDetails.hasBrakeAssist
    }),
    ...(productDetails.hasAntiLockBrakingSystem !== undefined && {
      hasAntiLockBrakingSystem: productDetails.hasAntiLockBrakingSystem
    }),
    ...(productDetails.hasEBD !== undefined && { hasEBD: productDetails.hasEBD }),
    ...(productDetails.hasESP !== undefined && { hasESP: productDetails.hasESP }),
    ...(productDetails.hasAntiTheftAlarm !== undefined && {
      hasAntiTheftAlarm: productDetails.hasAntiTheftAlarm
    }),
    ...(productDetails.hasEngineImmoblizer !== undefined && {
      hasEngineImmoblizer: productDetails.hasEngineImmoblizer
    }),
    ...(productDetails.has360DegreeCamera !== undefined && {
      has360DegreeCamera: productDetails.has360DegreeCamera
    }),
    ...(productDetails.hasAirBags !== undefined && { hasAirBags: productDetails.hasAirBags }),
    ...(productDetails.hasRunFlatTyre !== undefined && {
      hasRunFlatTyre: productDetails.hasRunFlatTyre
    }),
    ...(productDetails.hasAdaptiveBrakeLights !== undefined && {
      hasAdaptiveBrakeLights: productDetails.hasAdaptiveBrakeLights
    }),
    ...(productDetails.highlightedPoints !== undefined && {
      highlightedPoints: productDetails.highlightedPoints
    }),
    ...(productDetails.sourceAgentId !== undefined && {
      sourceAgentId: productDetails.sourceAgentId?.id ?? ''
    })
  };
}

function mapToUi(productDetails: FullProductDetailsModel): FullProductDetailsUiModel {
  const groupedFiles = productDocumentsMapper.mapToUi(productDetails.productImages ?? []);

  return {
    ...productDetails,
    sourceAgentId: {
      id: productDetails.sourceAgentId ?? '',
      label: productDetails.sourceAgent?.name ?? ''
    },
    companyId: {
      id: productDetails.companyId,
      label: productDetails.company?.companyName.en ?? '',
      imageUrl: productDetails.company?.url
    },
    branchId: {
      id: productDetails.branchId,
      label: `${productDetails.branch?.branchCity?.en} - ${productDetails.branch?.branchArea?.en}`
    },
    automotiveTypeId: {
      id: productDetails.automotiveTypeId ?? '',
      label: `${productDetails.automotiveType?.en} - ${productDetails.automotiveType?.ar}`
    },
    bodyTypeId: {
      id: productDetails.bodyTypeId ?? '',
      label: productDetails.bodyType?.name?.en ?? '',
      imageUrl: productDetails.bodyType?.imageUrl
    },
    motorType: {
      id: productDetails.motorType?.en ?? '',
      label: `${productDetails.motorType?.en} - ${productDetails.motorType?.ar}`
    },
    transmissionType: productDetails.transmissionType?.en,
    carBrandId: {
      id: productDetails.carBrandId ?? '',
      label: `${productDetails.carBrand?.name?.en} - ${productDetails.carBrand?.name?.ar}`,
      imageUrl: productDetails.carBrand?.url
    },
    carModelId: {
      id: productDetails.carModelId ?? '',
      label: `${productDetails.carModel?.en} - ${productDetails.carModel?.ar}`
    },
    carCategoryId: {
      id: productDetails.carCategoryId ?? '',
      label: `${productDetails.carCategory?.en} - ${productDetails.carCategory?.ar}`
    },
    colorId: {
      id: productDetails.colorId ?? '',
      label: `${productDetails.color?.en} - ${productDetails.color?.ar}`
    },
    manufactureYear: productDetails.manufactureYear?.toString(),
    carPrice: productDetails.carPrice,
    km: productDetails.km,
    noOfDoors: {
      id: productDetails.noOfDoors?.toString() ?? '',
      label: productDetails.noOfDoors?.toString() ?? ''
    },
    engineCapacity: {
      id: productDetails.engineCapacity?.toString() ?? '',
      label: productDetails.engineCapacity
        ? productDetails.motorType?.en === 'electric'
          ? `${productDetails.engineCapacity} KW`
          : `${productDetails.engineCapacity}cc`
        : ''
    },
    carChassis: productDetails.carChassis ?? '',
    motorNumber: productDetails.motorNumber ?? '',
    carDescriptionEn: productDetails.carDescription?.en ?? '',
    carDescriptionAr: productDetails.carDescription?.ar ?? '',
    numberOfCylinders: productDetails.numberOfCylinders
      ? {
          id: productDetails.numberOfCylinders?.toString() ?? '',
          label: productDetails.numberOfCylinders?.toString() ?? ''
        }
      : null,
    groundClearance: productDetails.groundClearance?.toString(),
    uploadCar360: productDetails.uploadCar360,
    productImages: groupedFiles,
    highlightedPoints: productDetails.highlightedPoints
  };
}

const productDetailsMapper = { mapToModel, mapToUi };

export default productDetailsMapper;
