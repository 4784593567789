import { FC } from 'react';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import StyledFormControl from '../form/styled-components/styled-form-control';
import { BpCheckedIcon, BpIcon } from './color-picker.styles';
import FormHelperText from '@mui/material/FormHelperText';

export const colors = [
  { id: 'gray', color: { primary: '#628FA1', secondary: '#D1E0E6' } },
  { id: 'purple', color: { primary: '#7A62A1', secondary: '#DAD5E3' } },
  { id: 'yellow', color: { primary: '#F5B746', secondary: '#FCF0DB' } },
  { id: 'green', color: { primary: '#32C47E', secondary: '#DFF9ED' } },
  { id: 'pink', color: { primary: '#ED84E9', secondary: '#F9DFF8' } },
  { id: 'blue', color: { primary: '#487BDF', secondary: '#DFE8F9' } }
];

export const getPrimaryColor = (color: string) => {
  return colors.find((c) => c.id === color)?.color.primary;
};

export const getSecondaryColor = (color: string) => {
  return colors.find((c) => c.id === color)?.color.secondary;
};

interface ColorPickerProps {
  data: {
    name: string;
    label: string;
    value: string;
    error: string;
  };
  eventHandlers: {
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
}

const ColorPicker: FC<ColorPickerProps> = ({ data, eventHandlers }) => {
  const { label, name, value, error } = data;
  const { handleInputChange } = eventHandlers;

  return (
    <StyledFormControl sx={{ '& .MuiFormGroup-root': { flexDirection: 'row' } }}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup name={name} value={value}>
        {colors.map((color) => {
          return (
            <FormControlLabel
              key={color.id}
              value={value}
              label=""
              control={
                <Radio
                  disableRipple
                  value={color.id}
                  onChange={handleInputChange}
                  checkedIcon={
                    <BpCheckedIcon
                      primaryColor={color.color.primary}
                      secondryColor={color.color.secondary}
                    />
                  }
                  icon={
                    <BpIcon
                      primaryColor={color.color.primary}
                      secondryColor={color.color.secondary}
                    />
                  }
                />
              }
            />
          );
        })}
      </RadioGroup>
      <FormHelperText error>{error ? error : ' '}</FormHelperText>
    </StyledFormControl>
  );
};

export default ColorPicker;
