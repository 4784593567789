import apisauce from 'apisauce';
import { SERVER_BASE_URL } from './routes-config';
import {
  cleanupUserData,
  getSessionCorrelationId,
  getUserAccessToken,
  getUserRefreshToken,
  isUserSalesTeam,
  setSessionCorrelationId,
  setUserTeam,
  setUserToken
} from '../services/helpers/auth-helper/auth-helper.service';
// import { userApi } from '../services/apis/user-api.service';
import { userRefreshToken } from '../services/data/user-data/auth.data';
import { isErrorInfo } from '../interfaces/error-info.interface';
import { getSalesTeamMemberDetailsById } from '../services/data/sales-management/sales-management.data';

const create = (baseURL = SERVER_BASE_URL) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Accept-Language': '*'
    },
    timeout: 240000
  });
  api.addRequestTransform(async (request) => {
    request.httpsAgent = {
      rejectUnauthorized: false
    };
    const sessionCorrelationId = getSessionCorrelationId();
    if (sessionCorrelationId) {
      request.headers['x-correlation-id'] = sessionCorrelationId;
    }

    const jwt = getUserAccessToken();
    if (jwt) request.headers.Authorization = `Bearer ${jwt}`;
  });
  api.addAsyncResponseTransform(async (response) => {
    if (response?.headers && response?.headers['x-correlation-id']) {
      setSessionCorrelationId(response?.headers['x-correlation-id']);
    }
    if (
      response.status === 401 &&
      response.config?.url &&
      response.config?.url?.indexOf('/auth/refreshToken') === -1
    ) {
      const refreshToken = await getUserRefreshToken();
      if (refreshToken) {
        const userData = await userRefreshToken(refreshToken);

        if (isErrorInfo(userData)) {
          cleanupUserData();
        } else {
          setUserToken({ ...userData, refreshToken });

          if (userData.userId && isUserSalesTeam()) {
            const teamData = await getSalesTeamMemberDetailsById(
              userData.userId,
              'DASHBOARD_MODULE'
            );
            if (isErrorInfo(teamData)) {
              console.log('no team detected');
            } else {
              if (teamData?.data?.team) {
                setUserTeam(teamData?.data?.team);
              }
            }
          }

          const accessToken = userData.accessToken;
          if (accessToken) api.setHeader('Authorization', `Bearer ${accessToken}`);
          // retry
          const data = await api.any(response.config as any);
          // replace data
          response.data = data.data;
          response.status = data.status;
          response.problem = data.problem;
          response.headers = data.headers;
          response.ok = data.ok;
        }
      } else {
        cleanupUserData();
      }
    }
  });

  return api;
};

const api = create();

export default api;
export type ApiType = typeof api;
