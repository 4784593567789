import { ApiResponse } from 'apisauce';

import api from '../../../config/http.config';
import debugConfig from '../../../config/debug.config';
import salesManagementFixtureService from '../../fixtures/sales-management/sales-management-api.service';
import { ErrorInfo } from '../../../interfaces/error-info.interface';
import { ResponseModel } from '../../../interfaces/response-model.interface';
import { SalesUsersRoles } from '../../../../Modules/SalesManagementModule/shared/enums/sales-roles.enum';
import {
  MemberTeamPerformancePayload,
  TeamMembersPayload,
  TeamPerformancePayload
} from '../../../../Modules/SalesManagementModule/shared/types/search-team-members';
import {
  CommissionSchemeModel,
  CommissionSchemeModelResponse,
  CommissionSchemePayload
} from '../../../models/commission-scheme.model';
import {
  KPIsModel,
  CreateSalesTeamModel,
  SalesTeamModelResponse,
  KPIModelResponse,
  SalesTeamKPIModelResponse,
  SalesTeamKPIModel,
  CreateSalesTeamMemberModel,
  CreateSalesTeamMemberResponse,
  TeamMemberModel,
  UpdateSalesTeamMemberModel,
  TeamMembersTableModel,
  SalesTeamModel,
  TeamPerformanceModel,
  RecoverMemberModel,
  MemberCommissionResponse
} from '../../../models/teams.model';

const apis = {
  // KPIs APIs
  getAllKPIs: (): Promise<ApiResponse<ResponseModel<KPIModelResponse>, ErrorInfo>> =>
    api.get('/salesKpi'),

  createKpi: (kpi: KPIsModel): Promise<ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>> =>
    api.post('/salesKpi', kpi),

  updateSalesTeamKPI: (
    teamKPIvalues: SalesTeamKPIModel
  ): Promise<ApiResponse<ResponseModel<SalesTeamKPIModelResponse>, ErrorInfo>> =>
    api.put('/salesTeamKpi', teamKPIvalues),

  getSalesTeamKPI: (
    teamId: string,
    kpiId: string
  ): Promise<ApiResponse<ResponseModel<SalesTeamKPIModelResponse>, ErrorInfo>> =>
    api.get(`/salesTeamKpi/${teamId}/${kpiId}`),

  // Teams APIs
  createSalesTeam: (
    team: CreateSalesTeamModel
  ): Promise<ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>> => api.post('/salesTeam', team),

  updateSalesTeam: (
    team: CreateSalesTeamModel
  ): Promise<ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>> => api.put('/salesTeam', team),

  getAllSalesTeams: (): Promise<ApiResponse<ResponseModel<SalesTeamModelResponse>, ErrorInfo>> =>
    api.get('/salesTeam'),

  // archive teams APIs
  archiveSalesTeam: (
    teamId: string
  ): Promise<ApiResponse<ResponseModel<{ message: string }>, ErrorInfo>> =>
    api.put('/salesTeam/archive', { id: teamId }),

  recoverSalesTeam: (teamId: string): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> =>
    api.put('/salesTeam/recover', { id: teamId }),

  deleteSalesTeam: (teamId: string): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> =>
    api.delete(`/salesTeam/${teamId}`),

  getAllArchiveSalesTeams: (): Promise<ApiResponse<ResponseModel<SalesTeamModel[]>, ErrorInfo>> =>
    api.get('/salesTeam/archive'),

  // Team Members APIs
  createSalesTeamMember: (
    teamMember: CreateSalesTeamMemberModel
  ): Promise<ApiResponse<ResponseModel<CreateSalesTeamMemberResponse>, ErrorInfo>> =>
    api.post('/salesTeamMember', teamMember),

  updateSalesTeamMember: (teamMember: UpdateSalesTeamMemberModel): Promise<any> =>
    api.put('/salesTeamMember', teamMember),

  searchTeamMembers: (
    payload: TeamMembersPayload
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>> =>
    api.post('/salesTeamMember/search', payload),

  getSalesTeamMemberDetailsById: (
    id: string
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel>, ErrorInfo>> =>
    api.get(`/salesTeamMember/${id}`),

  getSingleUserTree: (
    memberId: string
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel>, ErrorInfo>> =>
    api.get(`/salesTeamMember/detailed/${memberId}`),

  getTeamTree: (
    teamId: string
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>> =>
    api.get(`/salesTeamMember/team/detailed/${teamId}`),

  // archive team members APIs
  archiveSalesTeamMember: (
    memberId: string
  ): Promise<ApiResponse<ResponseModel<{ message: string }>, ErrorInfo>> =>
    api.delete(`/salesTeamMember/archived/${memberId}`),

  recoverSalesTeamMember: (
    payload: RecoverMemberModel
  ): Promise<ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>> =>
    api.put('/salesTeamMember/recover', payload),

  deleteSalesTeamMember: (memberId: string): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> =>
    api.delete(`/salesTeamMember/${memberId}`),

  searchArchivedTeamMembers: (
    payload: TeamMembersPayload
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>> =>
    api.post('/salesTeamMember/archived/search', payload),

  getArchiveTeamTree: (
    teamId: string
  ): Promise<ApiResponse<ResponseModel<TeamMemberModel[]>, ErrorInfo>> =>
    api.get(`/salesTeamMember/team/archived/detailed/${teamId}`),

  // commission scheme APIs
  createOrUpdateCommissions: (
    payload: CommissionSchemePayload
  ): Promise<ApiResponse<ResponseModel<CommissionSchemeModel[]>, ErrorInfo>> =>
    api.put('/salesCommission/bulk', payload),

  getCommissionsByRole: (
    roleName: SalesUsersRoles
  ): Promise<ApiResponse<ResponseModel<CommissionSchemeModelResponse>, ErrorInfo>> =>
    api.get(`/salesCommission/role/${roleName}`),

  // Team Members Commissions APIs
  searchTeamMembersCommissions: (
    payload: TeamMembersPayload
  ): Promise<ApiResponse<ResponseModel<TeamMembersTableModel[]>, ErrorInfo>> =>
    api.post('/salesTeamCommission/search', payload),

  getMemeberCommission: (
    memberId: string
  ): Promise<ApiResponse<ResponseModel<MemberCommissionResponse>, ErrorInfo>> =>
    api.get(`/salesTeamCommission/commission/user/${memberId}`),

  getTeamPerformance: (
    payload: TeamPerformancePayload
  ): Promise<ApiResponse<ResponseModel<{ personal: TeamPerformanceModel[] }>, ErrorInfo>> =>
    api.post('/salesTeamCommission/performance', payload),

  getAllTeamsPerformance: (
    payload: TeamPerformancePayload
  ): Promise<ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>> =>
    api.post('/salesTeamCommission/teams/performance', payload),

  getUserTeamPerformance: (
    payload: MemberTeamPerformancePayload
  ): Promise<ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>> =>
    api.post('/salesTeamCommission/user/performance', payload),

  getArchiveTeamPerformance: (
    teamId: string
  ): Promise<ApiResponse<ResponseModel<TeamPerformanceModel[]>, ErrorInfo>> =>
    api.post('/salesTeamCommission/archive/performance', { team: teamId })
};

export const salesManagementApi = debugConfig.useFixtures ? salesManagementFixtureService : apis;
