import React, { FC, useMemo } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { TARGET_URL_QUERY_PARAM } from '../../config/auth.config';
import { APP_ROUTES, PUBLIC_PATH } from '../../config/routes-config';
import { RouteConfig } from '../../interfaces/routes-config.interface';
import { UserRole } from '../../enums/user-role.enum';
import { Unauthorized } from '../../components/core-ui/unauthorized/unauthorized.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { UserTeams } from '../../enums/user-teams.enum';
import { isUserSalesTeam } from '../../services/helpers/auth-helper/auth-helper.service';
/**
 * interface ProtectedRouteProps
 */

interface ProtectedRouteProps {
  component: FC<any>;
  permittedList?: UserRole[];
  permittedTeamsList?: UserTeams[];
}

/**
 * functional component ProtectedRoute
 * @param component child component to be rendered in case of authenticated user
 * @param isAuthenticated user authentication status
 */

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  permittedList,
  permittedTeamsList,
  ...rest
}) => {
  const {
    accessToken: isAuthenticated,
    role: userRole,
    team: userTeam
  } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  const TARGET_URL = useMemo(() => {
    return PUBLIC_PATH ? location.pathname.replace(PUBLIC_PATH, '') : location.pathname;
  }, [location.pathname]);

  const userHasPermittedRole = useMemo(() => {
    return permittedList && userRole && permittedList.some((x) => userRole.includes(x));
  }, [permittedList, userRole]);

  const userInPermittedTeam = useMemo(() => {
    return !isUserSalesTeam()
      ? userHasPermittedRole
      : permittedTeamsList
      ? userTeam && userTeam.name && permittedTeamsList.includes(userTeam.name)
      : true;
  }, [permittedTeamsList, userTeam, userRole]);

  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH,
          search: `?${TARGET_URL_QUERY_PARAM}=${TARGET_URL}`
        }}
      />
    );
  }

  if (!userHasPermittedRole || !userInPermittedTeam) {
    return <Unauthorized />;
  }

  return <Component {...rest} />;
};
