import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../interfaces/error-info.interface';
import api from '../../../config/http.config';
import debugConfig from '../../../config/debug.config';
import financeApplicationsFixtureService from '../../fixtures/finance-applications/finance-applications-api.service';
import { ResponseModel } from '../../../interfaces/response-model.interface';
import { FinanceApplicationsOverviewReportModel } from '../../../models/finance-applications-overview-report.model';
import { FinanceApplicationsFrequencyReportModel } from '../../../models/finance-applications-frequency-report.model';
import { UserModel } from '../../../models/user.model';
import { FinanceApplicationsAgentReportModel } from '../../../models/finance-applications-agent-report.model';
import { ApplicationReportStatusEnum } from '../../../../Modules/CustomersModule/shared/enums/customer-finance-application.enum';
import { FinanceApplicationsMerchantReportModel } from '../../../models/finance-applications-merchant-report.model';

const apis = {
  overviewReport: (
    startDate: string,
    endDate: string,
    financialMerchantId?: string[],
    assignedTo?: string[]
  ): Promise<ApiResponse<ResponseModel<FinanceApplicationsOverviewReportModel>, ErrorInfo>> =>
    api.post('/customerApplicationData/report/overview', {
      query: { startDate, endDate, financialMerchantId, assignedTo }
    }),
  performanceReport: (
    startDate: string,
    endDate: string,
    applicationStatus: ApplicationReportStatusEnum,
    financialMerchantId?: string[],
    agentId?: string[]
  ): Promise<
    ApiResponse<ResponseModel<{ [key: string]: FinanceApplicationsAgentReportModel }>, ErrorInfo>
  > =>
    api.post('/customerApplicationData/report/agent', {
      query: {
        startDate,
        endDate,
        applicationStatus,
        financialMerchantId,
        agentId
      }
    }),
  merchantReport: (
    startDate: string,
    endDate: string,
    status: ApplicationReportStatusEnum
  ): Promise<ApiResponse<ResponseModel<FinanceApplicationsMerchantReportModel[]>, ErrorInfo>> =>
    api.post('/customerApplicationData/report/merchant', {
      query: {
        startDate,
        endDate,
        applicationStatus: status
      }
    }),
  getAllAgents: (): Promise<ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>> =>
    api.get('/customerApplicationData/agents'),
  frequencyReport: (
    frequency: 'daily' | 'monthly' | 'yearly',
    metric: 'amount' | 'count',
    startDate: string,
    endDate: string,
    financialMerchantId?: string[],
    assignedTo?: string[]
  ): Promise<ApiResponse<ResponseModel<FinanceApplicationsFrequencyReportModel>, ErrorInfo>> =>
    api.post('/customerApplicationData/report/frequency', {
      query: {
        frequency,
        metric,
        startDate,
        endDate,
        financialMerchantId,
        assignedTo
      }
    })
};

// TODO: Enhance the way of mocking APIs
export const financeApplicationsApi = debugConfig.useFixtures
  ? financeApplicationsFixtureService
  : apis;
