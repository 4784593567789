import Cookies from 'js-cookie';
import {
  userDataCleanup,
  userDataSuccess,
  userTeamDataSuccess
} from '../../../redux/actions/auth.actions';
import {
  SESSION_CORRELATION_ID,
  USER_ACCESS_TOKEN,
  USER_ID_COOKIE_NAME,
  USER_REFRESH_TOKEN,
  USER_TEAM,
  USER_TEAM_ID
} from '../../../config/cookies.config';
import ReduxStore from '../../../redux/store';
import { UserAuthUIModel } from '../../../ui-models/user-auth.ui-model';
import jwt_decode from 'jwt-decode';
import { UserRole } from '../../../enums/user-role.enum';

/**
 * function to get user token from data store
 * @returns {string | undefined}
 */
export function getUserAccessToken(): string | undefined {
  return ReduxStore.getState().auth.accessToken;
  // if(!token) {
  //   const tempToken = Cookies.get(USER_ACCESS_TOKEN);
  //   if(tempToken){
  //     token = tempToken;
  //     ReduxStore.dispatch(registerUserSuccess({
  //       accessToken: token,
  //       email:
  //     }));
  //   }
  // }
}

export function getUserRefreshToken(): string | undefined {
  return ReduxStore.getState().auth.refreshToken;
}

export function refreshUserToken(): UserAuthUIModel | null {
  const accessToken = Cookies.get(USER_ACCESS_TOKEN);
  const refreshToken = Cookies.get(USER_REFRESH_TOKEN);
  const salesUserTeam = Cookies.get(USER_TEAM);
  const salesUserTeamID = Cookies.get(USER_TEAM_ID) || '';
  if (accessToken && accessToken !== 'undefined' && refreshToken && refreshToken !== 'undefined') {
    const { email, userId, role, permissions, name, image } =
      jwt_decode<UserAuthUIModel>(accessToken);
    const userData: UserAuthUIModel = {
      accessToken,
      refreshToken,
      name,
      email,
      userId,
      tokenExpiresIn: 365,
      role,
      image,
      permissions
    };
    if (salesUserTeam && salesUserTeam !== 'undefined') {
      userData.team = { name: salesUserTeam, id: salesUserTeamID };
    }
    // ReduxStore.dispatch(registerUserSuccess(userData));
    ReduxStore.dispatch(
      userDataSuccess({
        accessToken: userData.accessToken,
        refreshToken: userData.refreshToken,
        tokenExpiresIn: userData.tokenExpiresIn,
        userId: userData.userId,
        email: userData.email,
        role: userData.role,
        permissions: userData.permissions,
        image: userData.image,
        name: userData.name,
        team: userData.team
      })
    );
    return userData;
  } else {
    return null;
  }
}

/**
 * function to set user token to cookies and data store
 * @param userData
 */
export function setUserToken(userData: UserAuthUIModel): void {
  Cookies.set(USER_ACCESS_TOKEN, userData.accessToken, { expires: userData.tokenExpiresIn });
  Cookies.set(USER_REFRESH_TOKEN, userData.refreshToken, { expires: userData.tokenExpiresIn });
  if (userData?.team) {
    Cookies.set(USER_TEAM, userData.team.name, { expires: userData.tokenExpiresIn });
    Cookies.set(USER_TEAM_ID, userData.team.id, { expires: userData.tokenExpiresIn });
  }
  try {
    const { name, permissions } = jwt_decode<UserAuthUIModel>(userData.accessToken);

    ReduxStore.dispatch(
      userDataSuccess({
        accessToken: userData.accessToken,
        refreshToken: userData.refreshToken,
        tokenExpiresIn: userData.tokenExpiresIn,
        userId: userData.userId,
        email: userData.email,
        image: userData.image,
        role: userData.role,
        team: userData.team,
        name,
        permissions
      })
    );
  } catch (error) {
    console.error('Error decoding JWT token:', error);
    ReduxStore.dispatch(userDataCleanup());
  }
}

export function setUserTeam(team: { name: string; id: string }): void {
  Cookies.set(USER_TEAM, team.name, { expires: 365 });
  Cookies.set(USER_TEAM_ID, team.id, { expires: 365 });
  try {
    ReduxStore.dispatch(
      userTeamDataSuccess({
        team
      })
    );
  } catch (error) {
    console.error('Error decoding JWT token:', error);
    ReduxStore.dispatch(userDataCleanup());
  }
}

export function hasAccess(allowedPermissions: string[]): boolean {
  console.log('evaluating permissions');
  const accessToken = Cookies.get(USER_ACCESS_TOKEN);
  if (accessToken && accessToken !== 'undefined') {
    const { permissions } = jwt_decode<UserAuthUIModel>(accessToken);
    if (permissions) {
      return allowedPermissions.some((item) => permissions.includes(item));
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function hasRole(allowedRoles: UserRole[]): boolean {
  console.log('evaluating permissions');
  const accessToken = Cookies.get(USER_ACCESS_TOKEN);
  if (accessToken && accessToken !== 'undefined') {
    const { role } = jwt_decode<UserAuthUIModel>(accessToken);
    if (role) {
      return allowedRoles.filter((x) => role.includes(x)).length > 0;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function teamHasAccess(allowedPermissions: string[]): boolean {
  console.log('evaluating permissions');
  const userTeam = Cookies.get(USER_TEAM);
  if (userTeam && userTeam !== 'undefined') {
    return allowedPermissions.indexOf(userTeam) > -1;
  } else {
    return false;
  }
}

/**
 * function to clean up user data from cookies and data store
 */
export function cleanupUserData() {
  ReduxStore.dispatch(userDataCleanup());
  Cookies.remove(USER_ACCESS_TOKEN);
  Cookies.remove(USER_REFRESH_TOKEN);
  Cookies.remove(USER_ID_COOKIE_NAME);
}

export function setSessionCorrelationId(correlationId: string) {
  sessionStorage.setItem(SESSION_CORRELATION_ID, correlationId);
}

export function getSessionCorrelationId(): string {
  return sessionStorage.getItem(SESSION_CORRELATION_ID) || '';
}

export function isUserSalesTeam(accessTokenToEvaluate?: string): boolean {
  console.log('evaluating permissions');
  const accessToken = accessTokenToEvaluate || Cookies.get(USER_ACCESS_TOKEN);
  if (accessToken && accessToken !== 'undefined') {
    const { role } = jwt_decode<UserAuthUIModel>(accessToken);
    if (role) {
      return (
        [
          UserRole.SALES_AGENT,
          UserRole.SALES_MANAGER,
          UserRole.E_COMMERCE_MANAGER,
          UserRole.E_COMMERCE_AUTO
        ].filter((x) => role.includes(x)).length > 0
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
}
